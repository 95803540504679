import type { ComponentProps } from 'react';
import LoadingSvg from '../icon/assets/loading.svg';

type LoadingProps = ComponentProps<'div'>;

const Loading = ({ ...props }: LoadingProps) => {
  return (
    <div {...props}>
      <LoadingSvg className="h-4 w-4" />
      <span className="sr-only">Loading</span>
    </div>
  );
};

Loading.displayName = 'Loading';

export default Loading;
