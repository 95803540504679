'use client';

import NewCustomerForm from './forms/NewCustomerDiscountForm';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@ngg/icons';
import createDictionary from '@utils/createDictionary';
import { getCookie, setCookie } from 'cookies-next';
import { useCallback, useEffect, useState } from 'react';
import { getSiteFooterInfo } from '@app/actions/siteinfo/actions';
import { useQuery } from '@tanstack/react-query';
import type { KeyValue } from '@business/gql/graphql';
import { cn } from '@ngg/storefront-utils';
import { FEATURE_FLAGS } from '@lib/config/featureFlags';

export default function NewCustomerDiscountPopup() {
  const data = useQuery({
    queryKey: ['siteinfoFetchQuery'],
    queryFn: () => getSiteFooterInfo(),
  });

  const dictionary = data?.data?.siteInfoDictionary;
  const form = data?.data?.siteinfo?.newsletter;

  const [newsletterCookie, setNewsletterCookie] = useState(
    getCookie('lm_newsletter')?.valueOf(),
  );
  const [dialog, setDialog] = useState(false);
  const [tooltip, setTooltip] = useState<boolean>(false);
  const onHide = useCallback(() => {
    setTooltip(false);
    setCookie('lm_newsletter', true, { maxAge: 60 * 60 * 24 * 7 });
    setNewsletterCookie(true);
  }, []);
  const onClose = useCallback(() => {
    setDialog(false);
    setTooltip(true);
  }, []);
  const onSuccessClose = useCallback(() => {
    setDialog(false);
    setTooltip(true);
    setNewsletterCookie(true);
  }, []);
  const onOpen = useCallback(() => {
    setDialog(true);
    setTooltip(false);
  }, []);

  useEffect(() => {
    setTooltip(getCookie('lm_newsletter')?.valueOf() !== true);
  }, [newsletterCookie]);

  const formDictionary = createDictionary(
    form?.dictionaryCollection?.items as KeyValue[],
  );

  return (
    <>
      <Transition
        show={tooltip}
        appear
        enter="transform transition duration-300 delay-2000"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={cn([
          'fixed bottom-0 left-0 z-10 p-4',
          !FEATURE_FLAGS.mobile.showNewCustomerDiscountPopup && 'max-md:hidden',
        ])}>
        <div
          role="tooltip"
          className="xs:max-w-[55vw] relative flex max-w-[44vw] items-center bg-black text-sm text-white">
          <button
            type="button"
            className="py-4 pl-4 text-left leading-none"
            onClick={onOpen}>
            {dictionary?.subscribeForDiscount}
          </button>
          <button type="button" className="p-4" onClick={onHide}>
            <XIcon />
            <span className="sr-only">{dictionary?.close ?? 'Close'}</span>
          </button>
        </div>
      </Transition>
      <Dialog open={dialog} onClose={onClose} className="relative z-20">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel className="relative mx-auto max-h-full max-w-xl items-start overflow-auto">
              <button
                type="button"
                onClick={onClose}
                className="absolute right-0 top-0 p-4">
                <XIcon className="text-base" />
              </button>
              <NewCustomerForm
                dictionary={formDictionary}
                onClose={onSuccessClose}
              />
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
