import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import { tv, type VariantProps } from 'tailwind-variants';
import Loading from '../Loading/Loading';
import { cn } from '@ngg/storefront-utils';

const buttonVariants = tv({
  base: 'inline-flex items-center text-center justify-center tracking-wide whitespace-nowrap transition-colors disabled:pointer-events-none disabled:opacity-50 max-md:text-sm',
  variants: {
    variant: {
      primary: 'bg-black text-white',
      secondary: 'bg-gray-200 hover:bg-gray-300',
      outline: 'border border-current',
      ghost: 'bg-transparent',
      link: 'underline underline-offset-2',
    },
    size: {
      sm: 'h-10 px-4',
      lg: 'h-11 px-6',
      icon: 'w-10 h-10',
    },
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}>
        {loading ? <Loading /> : children}
      </Comp>
    );
  },
);

Button.displayName = 'Button';

export default Button;
