import { KeyValue } from '@business/gql/graphql';
import { Dictionary } from '@ts/dictionary';

export default function createDictionary(list: KeyValue[] = []): Dictionary {
  const excludeFromLogging = [
    'toString',
    '0',
    'then',
    'vadd',
    '@@iterator',
    '$$typeof',
    'toJSON',
  ];

  const proxy = new Proxy<Dictionary>(
    {},
    {
      get(target, prop) {
        if (
          process.env.NODE_ENV === 'development' &&
          typeof prop === 'string' &&
          !(prop in target) &&
          !excludeFromLogging.includes(prop)
        ) {
          return `!!${prop}`;
        }
        return target[String(prop)];
      },
    },
  );

  list?.forEach((kv: KeyValue) => {
    const key = kv?.key as string;
    const value = kv?.value as string;
    proxy[key] = value;
  });

  return proxy;
}
