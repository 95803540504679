type Feature = Readonly<boolean>;

export type FeatureFlags = typeof FEATURE_FLAGS;
export const FEATURE_FLAGS = {
  mobile: {
    showNewCustomerDiscountPopup: getFeatureOn(
      process.env.NEXT_PUBLIC_FEATURES_MOBILE_NEW_CUSTOMER_DISCOUNT_POPUP,
    ),
  },
  desktop: {},
  cache: {
    loop: getFeatureOn(process.env.NEXT_PUBLIC_FEATURES_CACHE_LOOP),
  },
  explodeVariants: {
    search: getFeatureOn(process.env.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_SEARCH),
    products: getFeatureOn(process.env.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_PRODUCTS),
  }
} as const;

function getFeatureOn(value?: string): Feature {
  const compare = value?.localeCompare('true', undefined, {
    sensitivity: 'accent',
  });

  return compare === 0;
}
