'use client';

import { Input } from '@ngg/components';
import { CheckIcon } from '@ngg/icons';
import { cn } from '@ngg/storefront-utils';
import type { Dictionary } from '@ts/dictionary';
import Link, { LinkOutlined } from '@ui/components/Link/Link';
import Button from '@ui/components/button/Button';
import { getExternalStoreId } from '@utils/common';
import useRegisterUser from '@utils/hooks/user/useRegisterUser';
import { FormEvent, useCallback, useState } from 'react';
import { setCookie } from 'cookies-next';
import config from '@utils/config';
import { useRouter } from 'next/navigation';
import { Heading } from '@ui/components/Heading/Heading';
import Paragraph from '@ui/components/Paragraph/Paragraph';

type Props = {
  dictionary: Dictionary;
  onClose?: () => void;
  classNames?: {
    instagramButton?: string;
    emailInput?: string;
    submitButton?: string;
    selectWrapper?: string;
    selectOption?: string;
    label?: string;
  };
  onlyForm?: boolean;
};

const REGISTER_USER_SOURCE = 'new-form';

export default function NewCustomerForm({
  classNames,
  dictionary,
  onClose,
  onlyForm = false,
}: Props) {
  const {
    mutate,
    isPending,
    isError,
    isSuccess,
    error: mutationError,
  } = useRegisterUser();
  const [showMobileCheckIcon, setShowMobileCheckIcon] = useState(false); // Show check icon per visit when we can't read visited history
  const router = useRouter();
  const { market, locale } = config;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.target as HTMLFormElement);
    const email = data.get('email') as string;
    mutate({
      contact: {
        email,
        countryCode: market,
        firstName: '',
        lastName: '',
        mobilePhone: '',
        externalStoreId: getExternalStoreId(market),
        source: REGISTER_USER_SOURCE,
      },
    });
  };

  const closeAfterSuccess = useCallback(() => {
    if (onClose) {
      onClose();
      setCookie('lm_newsletter', true, { maxAge: 60 * 60 * 24 * 7 });
      return;
    }
    router.push('/');
    setCookie('lm_newsletter', true, { maxAge: 60 * 60 * 24 * 7 });
  }, [onClose, router]);

  if (isSuccess) {
    return (
      <div className={cn('', !onlyForm && 'bg-main p-4 pt-10')}>
        <Heading className="text-lg">
          {dictionary?.newCustomerFormSuccessHeading}
        </Heading>
        <Paragraph className="mt-4 text-xs">
          {dictionary?.newCustomerFormSuccessText}
        </Paragraph>
        <Button
          onClick={closeAfterSuccess}
          className="btn-primary mt-4 w-full bg-black py-2 text-sm text-white">
          {dictionary?.newCustomerFormSuccessButtonText}
        </Button>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={cn(
        'grid md:grid-cols-2',
        onlyForm && ' grid w-full grid-cols-1 md:grid-cols-1',
      )}>
      {!onlyForm && (
        <div className="flex flex-col bg-main px-4 pt-10 md:bg-beige md:px-6 md:py-10">
          <Heading className="text-lg">
            {dictionary?.newCustomerFormHeading}
          </Heading>

          <Paragraph className="my-4 flex-1 text-sm">
            {dictionary?.newCustomerFormInfoText}
          </Paragraph>
          <Paragraph className="text-xs">
            {' '}
            {dictionary?.newCustomerFormTermsText}{' '}
            <Link
              locale={locale}
              className="underline"
              onClick={onClose}
              href={dictionary?.newCustomerFormTermsLink}>
              {dictionary?.newCustomerFormTermsLinkText}
            </Link>
          </Paragraph>
        </div>
      )}
      <div
        className={cn(
          'flex flex-col ',
          onlyForm && 'w-full max-w-full',
          !onlyForm && 'md:pb-19 bg-main px-4 py-6 md:px-6 md:pt-20',
        )}>
        <label
          className={cn(
            'mb-2 block text-xs font-bold md:pt-1',
            classNames?.label,
          )}>
          {!onlyForm && '1. '}
          {dictionary?.newCustomerFormInstagramButtonLabel}
        </label>
        <LinkOutlined
          href={
            dictionary?.newCustomerFormInstagramUrl ||
            'https://www.instagram.com/lannamobler/'
          }
          className={cn(
            'group',
            'bg-main font-bold text-black',
            'visited:bg-beige',
            onlyForm && 'bg-black text-white visited:bg-black',
          )}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => setShowMobileCheckIcon(true)}
          startIcon={
            <span
              className={cn(
                'flex items-center',
                '-ml-[14px] rounded-full',
                'bg-main group-visited:bg-green group-hover:bg-black group-hover:group-visited:bg-green',
                onlyForm && 'bg-black',
                showMobileCheckIcon && 'bg-green',
              )}>
              <CheckIcon
                className={cn(
                  'pt-[1px]',
                  'text-main',
                  'group-hover:text-black',
                  'group-visited:text-white group-hover:group-visited:text-white',
                  onlyForm && 'text-black',
                  showMobileCheckIcon && 'text-white',
                )}
              />
            </span>
          }>
          {dictionary?.newCustomerFormInstagramButtonText}
        </LinkOutlined>
        <div>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder={dictionary?.newCustomerFormEmailInputPlaceholder}
            required
            fullWidth
            label={`${!onlyForm ? '2. ' : ''}${
              dictionary?.newCustomerFormEmailInputLabel
            }`}
            classNames={{
              wrapper: 'mt-4 mb-3',
              label: cn('text-xs font-bold mb-2', classNames?.label),
              input: cn(
                'h-10 w-full px-3 py-0 text-md placeholder:text-xs placeholder:text-black',
                classNames?.emailInput,
              ),
            }}
          />

          {isError && mutationError?.message === 'Conflict' ? (
            <div className="mt-4 text-sm text-error">
              <Paragraph className="font-medium">
                {dictionary?.newCustomerFormErrorHeading}
              </Paragraph>
              <Paragraph>
                {dictionary?.newCustomerFormYouAreAlreadyRegistered}
              </Paragraph>
            </div>
          ) : null}

          <Button
            type="submit"
            className={cn(
              'btn-primary w-full bg-black py-2 text-sm font-bold text-white',
              classNames?.submitButton,
            )}
            loading={isPending}>
            {dictionary?.formSendButtonTextNewCustomer}
          </Button>
        </div>
      </div>
    </form>
  );
}
