import { useMutation } from '@tanstack/react-query';
import { MutationRegisterUserArgs } from '@business/gql/graphql';
import { registerUserAction } from '@actions/user/actions';
import { MutationKeys } from '../../mutations/mutationKeys';
import { setCookie } from 'cookies-next';

export default function useRegisterUser() {
  return useMutation({
    mutationKey: [MutationKeys.registerUser],
    mutationFn: (payload: MutationRegisterUserArgs) =>
      registerUserAction({ ...payload, brandCode: 'LM' }),
    onSuccess: () => {
      setCookie('lm_newsletter', true, { maxAge: 60 * 60 * 24 * 7 });
    },
  });
}
